var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"supervisor"},[_c('i',{staticClass:"fad fa-user-robot"}),_vm._v(" "+_vm._s(_vm.user.name)+" "),_c('a',{on:{"click":_vm.log_out}},[_c('i',{staticClass:"fa-solid fa-arrow-left"})])]),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              startDate: '',
              endDate: '',
            },
            path: '/daily-report',
          }}},[_c('i',{staticClass:"fa-solid fa-bug"}),_vm._v(" Daily Report ")])],1),_vm._m(0),_c('li',[_c('router-link',{attrs:{"to":"/dt-list"}},[_c('i',{staticClass:"fa-solid fa-chart-area"}),_vm._v(" DT List ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              selectedSupervisor: '',
              selectedStatus: '',
              page: 1,
              perPage: 10,
              searchOutlet: '',
              selectDT: '',
              type: 'champa',
            },
            path: '/champa-outlet',
          }}},[_c('i',{staticClass:"fa-solid fa-shop"}),_vm._v(" Champa - Outlet ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              selectedSupervisor: '',
              selectedStatus: '',
              page: 1,
              perPage: 10,
              searchChampa: '',
              type: 'champa',
            },
            path: '/champa-lady',
          }}},[_c('i',{staticClass:"fa-solid fa-user-group"}),_vm._v(" Champa - Lady ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              selectedSupervisor: '',
              selectedStatus: '',
              page: 1,
              perPage: 10,
              searchResigned: '',
              type: 'champa',
              champaHistory: 'champaHistory',
            },
            path: '/champa-history',
          }}},[_c('i',{staticClass:"fa-solid fa-users-slash"}),_vm._v(" Champa - History ")])],1),_vm._m(1),_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              selectedSupervisor: '',
              selectedStatus: '',
              page: 1,
              perPage: 10,
              searchOutlet: '',
              selectDT: '',
              type: 'BA',
            },
            path: '/champa-outlet',
          }}},[_c('i',{staticClass:"fa-solid fa-shop"}),_vm._v(" BA - Outlet ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              selectedSupervisor: '',
              selectedStatus: '',
              page: 1,
              perPage: 10,
              searchChampa: '',
              type: 'BA',
            },
            path: '/champa-lady',
          }}},[_c('i',{staticClass:"fa-solid fa-users"}),_vm._v(" BA - Lady ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              selectedSupervisor: '',
              selectedStatus: '',
              page: 1,
              perPage: 10,
              searchResigned: '',
              type: 'BA',
              baHistory: 'baHistory',
            },
            path: '/champa-history',
          }}},[_c('i',{staticClass:"fa-solid fa-users-slash"}),_vm._v(" BA - History ")])],1),_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":{
            query: {
              month: _vm.formatMonth,
            },
            path: '/',
          },"exact":""}},[_c('i',{staticClass:"fa-solid fa-chart-pie"}),_vm._v(" Summary ")])],1)])]),_c('div',{staticClass:"content-area"},[_c('router-view')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"divider"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"divider"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"divider"})])}]

export { render, staticRenderFns }