import axios from "axios";
import router from "../../router/index";

// axios.defaults.headers['Authorization'] = localStorage.getItem("accessToken")

const state = {
  accessToken: localStorage.getItem("accessToken") || null,
};

const getters = {
  getToken: (state) => state.accessToken,
  isAuth: (state) => state.accessToken !== null,
};

const mutations = {
  SET_TOKEN(state, token) {
    state.accessToken = token;
  },
  CLEAR_TOKEN(state) {
    (state.accessToken = null), localStorage.clear();
  },
};

const actions = {
  async login(context, { email, password }) {
    const res = await axios.post(
      // `http://localhost:4000/auth/api/user-login`,
      `https://db-champa.semicolon.la/auth/api/user-login`,
      {
        email,
        password,
      }
    );
    const accessToken = res.data.accessToken;
    localStorage.setItem(`accessToken`, accessToken);
    context.commit(`SET_TOKEN`, accessToken);

    router.push({
      query: {
        month: "",
      },
      path: "/dt-list",
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
//https://db-champa.semicolon.la/auth/api/user-login
