import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.all";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import * as VueGoogleMaps from "vue2-google-maps";
// import VueCurrencyInput from 'vue-currency-input'

//googel map
Vue.use(VueGoogleMaps, {
  
  load: {
    key: "AIzaSyC6NsCxLuN120Gy8IkZrqEFhq9JSPkPWD8",
    libraries: "places",
  },
});

Vue.prototype.$moment = moment;
// Vue.use(VueCurrencyInput);
Vue.use(require("vue-moment"));
Vue.use(VueSweetalert2);
axios.defaults.headers["Authorization"] = store.getters.getToken;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

// export const serve = `http://localhost:4000/`
export const serve = `https://db-champa.semicolon.la/`;
Vue.prototype.$server = serve;
Vue.prototype.$timeout = 1000;

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
extend("required", {
  ...required,
  message: "please enter information",
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
