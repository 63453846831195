<template>
  <div id="app">
    <div class="sidebar">
      <div class="supervisor">
        <i class="fad fa-user-robot"></i> {{ user.name }}
        <a @click="log_out"> <i class="fa-solid fa-arrow-left"></i></a>
      </div>
      <ul>
        <li>
          <router-link
            :to="{
              query: {
                startDate: '',
                endDate: '',
              },
              path: '/daily-report',
            }"
          >
            <i class="fa-solid fa-bug"></i> Daily Report
          </router-link>
        </li>

        <li>
          <div class="divider"></div>
        </li>
        <li>
          <router-link to="/dt-list">
            <i class="fa-solid fa-chart-area"></i> DT List
          </router-link>
        </li>

        <li>
          <router-link
            :to="{
              query: {
                selectedSupervisor: '',
                selectedStatus: '',
                page: 1,
                perPage: 10,
                searchOutlet: '',
                selectDT: '',
                type: 'champa',
              },
              path: '/champa-outlet',
            }"
          >
            <i class="fa-solid fa-shop"></i> Champa - Outlet
          </router-link>
        </li>

        <li>
          <router-link
            :to="{
              query: {
                selectedSupervisor: '',
                selectedStatus: '',
                page: 1,
                perPage: 10,
                searchChampa: '',
                type: 'champa',
              },
              path: '/champa-lady',
            }"
          >
            <i class="fa-solid fa-user-group"></i> Champa - Lady
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              query: {
                selectedSupervisor: '',
                selectedStatus: '',
                page: 1,
                perPage: 10,
                searchResigned: '',
                type: 'champa',
                champaHistory: 'champaHistory',
              },
              path: '/champa-history',
            }"
          >
            <i class="fa-solid fa-users-slash"></i> Champa - History
          </router-link>
        </li>

        <li>
          <div class="divider"></div>
        </li>

        <li>
          <router-link
            :to="{
              query: {
                selectedSupervisor: '',
                selectedStatus: '',
                page: 1,
                perPage: 10,
                searchOutlet: '',
                selectDT: '',
                type: 'BA',
              },
              path: '/champa-outlet',
            }"
          >
            <i class="fa-solid fa-shop"></i> BA - Outlet
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              query: {
                selectedSupervisor: '',
                selectedStatus: '',
                page: 1,
                perPage: 10,
                searchChampa: '',
                type: 'BA',
              },
              path: '/champa-lady',
            }"
          >
            <i class="fa-solid fa-users"></i> BA - Lady
          </router-link>
        </li>
        <li>
          <router-link
            :to="{
              query: {
                selectedSupervisor: '',
                selectedStatus: '',
                page: 1,
                perPage: 10,
                searchResigned: '',
                type: 'BA',
                baHistory: 'baHistory',
              },
              path: '/champa-history',
            }"
          >
            <i class="fa-solid fa-users-slash"></i> BA - History
          </router-link>
        </li>

        <li>
          <div class="divider"></div>
        </li>

        <li>
          <router-link
            :to="{
              query: {
                month: formatMonth,
              },
              path: '/',
            }"
            exact
          >
            <i class="fa-solid fa-chart-pie"></i> Summary
          </router-link>
        </li>
      </ul>
    </div>

    <div class="content-area">
      <router-view />
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";
import moment from "moment";
export default {
  data: () => ({
    logoutClicked: false,
    user: {},
    formatMonth: "",
  }),
  watch: {
    $route: {
      handler() {
        if (this.$store.getters.getToken) {
          this.FetchAdminId();
          // alert("eiei");
        }
        // this.settime();
      },
      immediate: true,
    },
  },
  methods: {
    log_out() {
      this.$store.commit("CLEAR_TOKEN");
      // this.$router.push({
      //   path: "/auth",
      // });
      window.location.href = "/auth";
    },
    // settime() {
    //   this.settimeLogout = setTimeout(this.log_out, 60 * 60 * 1000 * 24);
    // },
    close() {
      (this.toggle = false), (this.logoutClicked = false);
    },

    FetchAdminId() {
      this.$axios.defaults.headers[
        "Authorization"
      ] = this.$store.getters.getToken;
      
      this.$axios
        .get(`${this.$server}api/admin-find-id`)
        .then((response) => {
          this.user = response.data.findId;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$store.commit("CLEAR_TOKEN");
            // this.$router
            //   .push({
            //     path: "/auth",
            //   })
            window.location.href = "/auth";

            // setTimeout(() => {
            //   location.reload();
            // }, 500);
          }
        });

      const currentDate = moment().startOf("day");
      this.formatMonth = moment(currentDate).format("YYYY-MM");
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
<style lang="scss">
@import "../public/scss/screen";
html,
body {
  font-family: var(--font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000 !important;
  background-color: #fcfcfc;
  overflow: hidden;
}

.section {
  padding: 4rem 1.5rem 3rem;
}

#app {
  display: flex;
  height: 100vh;
  .sidebar {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid var(--border-color);
    .sidebar-top {
      padding: 10px;
      border-bottom: 1px solid var(--border-color);
      h3 {
        font-weight: 700;
        font-size: var(--xlg-font);
        text-align: center;
        text-transform: uppercase;
      }
    }
    .supervisor {
      padding: 10px 0;
      text-align: center;
      border-bottom: 1px solid var(--border-color);
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      position: relative;
      i {
        margin-right: 10px;
        color: var(--grey-color);
      }
      a {
        position: absolute;
        left: 0;
        border-right: 1px solid var(--border-color);
        padding: 10px;
        background-color: var(--light-grey-color);
        i {
          margin: 0;
        }
      }
    }
    ul {
      flex-grow: 1;
      position: relative;
      transition: all ease 0.3s;
      padding: 10px 0;
      overflow: auto;
      position: relative;
      li {
        cursor: pointer;
        display: flex;
        width: 100%;
        padding: 0;
        .divider {
          margin: 8px auto;
          height: 1px;
          width: 100%;
          content: "";
          display: block;
          background-color: var(--light-grey-color);
        }
        a {
          width: 100%;
          transition: ease 0.3s;
          padding: 8px 15px;
          border-radius: 5px;
          display: flex;
          gap: 10px;
          align-items: center;
          white-space: nowrap;
          color: #000;
          font-size: var(--md-font);
          &.router-link-exact-active {
            // background-color: rgba(var(--primary-color), 0.09);
            color: var(--primary-color);
            i {
              background-color: var(--primary-color);
              color: #fff;
              font-size: var(--xsm-font);
            }
            &::after {
              content: "";
              width: 3px;
              height: 25px;
              background-color: var(--primary-color);
              position: absolute;
              right: 0px;
              border-radius: 3px 0 0 3px;
            }
          }
          i {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            background-color: var(--light-grey-color);
            border-radius: 3px;
            color: var(--primary-color);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: var(--xsm-font);
          }
        }
      }
    }
    .sidebar-bottom {
      margin-top: auto;
      padding: 0 10px;
      border-top: 1px solid var(--border-color);
      a {
        width: 100%;
        transition: ease 0.3s;
        padding: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        color: #000;
        i {
          width: 30px;
          height: 30px;
          min-width: 30px;
          min-height: 30px;
          background-color: var(--light-grey-color);
          border-radius: 3px;
          color: var(--primary-color);
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
        }
      }
    } // sidebar-buttom
  } // sidebar
  .sub-sidebar {
    z-index: 999;
    position: relative;
    height: 100%;
    max-width: 280px;
    width: 100%;
    border-right: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    padding: 0;
    .sub-sidebar-header {
      padding: 10px;
      .button-group {
        button.button {
          &:nth-child(1) {
            width: 100%;
          }
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        li {
          width: 48%;
        }
      }
    }
    .sub-sidebar-list {
      width: 100%;
      li {
        padding: 8px 10px;
        border-bottom: 1px solid var(--border-color);
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        &:first-child {
          border-top: 1px solid var(--border-color);
        }
        &.selected {
          background-color: var(--sub-color);
          color: #fff;
          span {
            color: #fff !important;
          }
        }
        span {
          display: block;
          cursor: pointer;
        }
        p {
          display: flex;
          align-items: center;
          span {
            padding: 0;
            &:nth-child(1) {
              color: var(--primary-color);
            }
            &:nth-child(2) {
              padding: 0 5px;
            }
          }
        }
      }
    }
  } // sub-sidebar
  .content-area {
    width: 100%;
    overflow: hidden;
    box-shadow: 3px 0 8px 8px rgba(#000, 0.01);
    section.section {
      padding: 30px;
    }
  }
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
  &.editable li {
    cursor: pointer;
    &:hover {
      color: var(--sub-color);
    }
    .edit {
      color: var(--sub-color);
      cursor: pointer;
    }
  }
  li {
    transition: all ease-in-out 0.15s;
  }
}
.scrollable-auto {
  overflow: auto;
}

.error {
  border: 2px solid var(--alert-color);
  &.is-error {
    color: var(--alert-color);
    border: none;
  }
}

.errorText {
  border-bottom: 2px solid var(--alert-color) !important;
}

.box-error {
  border-radius: 2px;
  background-color: #f1bfbf;
  padding: 4px;
  margin: 15px;
  .error_text {
    color: var(--alert-color);
    font-size: 16px;
    text-align: center;
  }
}

.page-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  transition: all ease-in-out 0.19s;
  .page-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    transition: all ease-in-out 0.19s;
    &.small {
      width: 50%;
    }
    &.medium {
      width: 70%;
    }
    .page-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid var(--border-color);
      transition: all ease-in-out 0.19s;
      &.no-border {
        border-bottom: none;
      }
      h3 {
        font-size: var(--lg-font);
        font-weight: 700;
        margin-right: 10px;
        white-space: nowrap;
      }
      .page-header-start {
        display: flex;
        align-items: center;
      }
      .page-header-end {
        display: flex;
        align-items: center;
        p {
          margin-right: 10px;
          white-space: nowrap;
        }
        ul {
          display: flex;
          align-items: center;
          li {
            display: flex;
            align-items: center;
            margin-left: 10px;
            h3 {
              color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}
.table-container {
  overflow: auto;
}

.loading_nav {
  width: 155px;
  height: 50px;
  // border-radius: 8px;
  border: 1px solid hsl(0, 0%, 90%);
  background: #fff;
  // margin-bottom: 5px;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: slide;
  animation-name: slide;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #e4e4e4 18%, #eeeeee 33%);
  // background-size: 800px 104px;
  top: 0;
}

.loading {
  width: 100%;
  height: 50px;
  // border-radius: 8px;
  border: 1px solid hsl(0, 0%, 90%);
  background: #fff;
  margin-bottom: 5px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: slide;
  animation-name: slide;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #e4e4e4 18%, #eeeeee 33%);
  // background-size: 800px 104px;
  top: 0;
}

.img_loading {
  width: 100%;
  height: 338px;
  // display: flex;
  border: 1px solid #dedede;
  background: #fff;
  margin-bottom: 5px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: slide;
  animation-name: slide;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #e4e4e4 18%, #eeeeee 33%);
  //   background-size: 800px 104px;
  top: 0;
}

@keyframes slide {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
</style>
