import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      component: () => import("../views/404.vue"),
    },
    {
      path: "/auth",
      name: "Auth",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/auth.vue"),

      meta: {
        requiresVisitor: true,
      },
    },

    {
      path: "/attendance",
      name: "attendance",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/Attendance.vue"),
    },
    {
      path: "/health-check",
      name: "health_check",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/Health-Check.vue"),
    },
    {
      path: "/problem-report",
      name: "problem_report",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/Problem-Report.vue"),
    },
    {
      path: "/daily-report",
      name: "daily_report",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/Daily-Report.vue"),
    },
    {
      path: "/champa-outlet",
      name: "champa_outlet",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/Champa-Outlet.vue"),
    },
    {
      path: "/ba-outlet",
      name: "ba_outlet",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/BA-Outlet.vue"),
    },
    {
      path: "/",
      name: "summary",
      component: () =>
        import(/* webpackChunkName: "Auth" */ "../views/Summary.vue"),
    },

    // {
    //   path: '/',
    //   name: 'Task',
    //   component: () => import('../views/Task-List.vue'),
    //   meta: {
    //     requiresAuth: true,
    //   }
    // },
    // {
    //   path: '/form',
    //   name: 'Form',
    //   component: () => import('../views/Form.vue'),
    //   meta: {
    //     requiresAuth: true,
    //   }
    // },
    // {
    //   path: '/task/:id',
    //   component: () => import('../views/Task-Item.vue'),
    //   children: [
    //     {
    //       path: 'task-item',
    //       name: 'TaskItem',
    //       component: () => import('../components/todo/task-item-list.vue'),
    //     },
    //     {
    //       path: 'task-checker',
    //       name: 'TaskChecker',
    //       component: () => import('../components/todo/task-checker.vue'),
    //     }
    //   ]
    // },
    {
      path: "/dt-list",
      name: "dt_list",
      component: () =>
        import(/* webpackChunkName: "area" */ "../views/DT-List.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    // {
    //   path: '/shop-list',
    //   name: 'shop',
    //   component: () => import('../views/Shop-List.vue'),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
    {
      path: "/champa-lady",
      name: "champa_lady",
      component: () =>
        import(/* webpackChunkName: "shop" */ "../views/Champa-Lady.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ba-lady",
      name: "ba_lady",
      component: () =>
        import(/* webpackChunkName: "shop" */ "../views/BA-Lady.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/champa-history",
      name: "champa_history",
      component: () =>
        import(/* webpackChunkName: "shop" */ "../views/Champa-History.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ba-history",
      name: "ba_history",
      component: () =>
        import(/* webpackChunkName: "shop" */ "../views/BA-History.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/salary",
      name: "salary",
      component: () =>
        import(/* webpackChunkName: "shop" */ "../views/Salary.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

//Login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (!store.getters.isAuth) {
      next();
      return;
    }
    next("/auth");
  }
  next();
});

import store from "../store";
//Dont login
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuth) {
      next();
      return;
    }
    next("/auth");
  }
  next();
});
export default router;
